import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiSearchRequestBody,
  FeatureApiClientBase,
} from '@ms/angular-workspace/dist/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ITenant } from '../store/models';

@Injectable({
  providedIn: 'root',
})
export class TenantsService extends FeatureApiClientBase<ITenant> {
  private httpClient;
  constructor(handler: HttpBackend) {
    const httpClient = new HttpClient(handler);
    super(httpClient, 'tenant');
    this.httpClient = httpClient;
  }
  deactivateSubscription(id: string): Observable<void> {
    return this.httpClient
      .delete<void>(this.url(`${id}/deactivate-subscription`))
      .pipe(map((response) => response));
  }

  exportCSV(data: ApiSearchRequestBody | void) {
    return this.httpClient
      .post(this.url('export'), data, { responseType: 'blob' })
      .pipe(map((response) => response));
  }
  reactivateSubscription(id: string) {
    return this.httpClient
      .post(this.url(`${id}/activate-subscription`), {})
      .pipe(map((response) => response));
  }
}
