import { State, StateContext, Selector } from '@ngxs/store';
import { Injectable, Injector } from '@angular/core';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { Observable, throwError, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  ApiSearchIncludeType,
  ApiSearchRequestBody,
  ApiSearchResponseData,
  downloadBlob,
} from '@ms/angular-workspace/dist/core';

import { ITenant, IUserProfile } from './models';
import { UsersService } from '../services/users.service';

@Injectable()
export class UsersStateModel {
  users: ApiSearchResponseData<IUserProfile> | null;
  usersTenants: ITenant[];
}

@State<UsersStateModel>({
  name: 'users',
  defaults: {
    users: null,
    usersTenants: [],
  },
})
@Injectable()
export class UsersState {
  private static usersService: UsersService;

  constructor(injector: Injector) {
    UsersState.usersService = injector.get<UsersService>(UsersService);
  }

  @Selector()
  static users(state: UsersStateModel) {
    return state.users;
  }

  @Selector()
  static usersTenants(state: UsersStateModel) {
    return state.usersTenants;
  }

  @Receiver({ type: '[Users] Search Users' })
  public static search(
    ctx: StateContext<UsersStateModel>,
    action: EmitterAction<ApiSearchRequestBody>
  ) {
    return this.usersService
      .search({
        ...action.payload,
      })
      .pipe(
        tap((response) => {
          ctx.patchState({
            users: response.data,
          });
          return of(response.data);
        })
      );
  }

  @Receiver({ type: '[Users] Export Users' })
  public static exportCSV(
    ctx: StateContext<UsersStateModel>,
    action: EmitterAction<ApiSearchRequestBody | void>
  ) {
    return this.usersService.exportCSV(action.payload).pipe(
      tap((response) => {
        downloadBlob(response, 'Users Export.csv');
        return of(response);
      }),
      catchError((response) => throwError(response.error))
    );
  }

  @Receiver({ type: '[Users] Get Users Tenants' })
  public static getUsersTenants(
    ctx: StateContext<UsersStateModel>,
    action: EmitterAction<ApiSearchRequestBody>
  ) {
    return this.usersService
      .search({
        currentPage: 0,
        pageSize: -1,
        filters: [],
        includes: ApiSearchIncludeType.Full,
      })
      .pipe(
        tap((response) => {
          ctx.patchState({
            usersTenants: [
              ...new Map(
                response.data.items
                  .map(({ tenant }) => tenant)
                  .filter(Boolean)
                  .map((item) => [item.id, item])
              ).values(),
            ].sort((a, b) => a.name.localeCompare(b.name)),
          });
        })
      );
  }

  @Receiver({ type: '[Users] Create User' })
  public static create(
    ctx: StateContext<UsersStateModel>,
    action: EmitterAction<IUserProfile>
  ) {
    return this.usersService.create(action.payload).pipe(
      tap((response) => Observable.create(response)),
      catchError((response) => throwError(response.error))
    );
  }

  @Receiver({ type: '[Users] Patch User' })
  public static patch(
    ctx: StateContext<UsersStateModel>,
    action: EmitterAction<{
      currentItem: IUserProfile;
      propertiesToUpdate: string[];
    }>
  ) {
    return this.usersService
      .patch(action.payload.currentItem, action.payload.propertiesToUpdate)
      .pipe(tap((response) => Observable.create(response)));
  }

  @Receiver({ type: '[Users] Resend Invite' })
  public static resendInvite(
    ctx: StateContext<UsersStateModel>,
    action: EmitterAction<IUserProfile>
  ) {
    return this.usersService.resendInvite(action.payload.id).pipe(
      tap((response) => Observable.create(response)),
      catchError((response) => throwError(response.error))
    );
  }

  @Receiver({ type: '[Users] Delete User' })
  public static delete(
    ctx: StateContext<UsersStateModel>,
    action: EmitterAction<IUserProfile>
  ) {
    return this.usersService.delete(action.payload.id).pipe(
      tap((response) => Observable.create(response)),
      catchError((response) => throwError(response.error))
    );
  }

  @Receiver({ type: '[Users] Activate' })
  public static activate(
    ctx: StateContext<UsersStateModel>,
    action: EmitterAction<IUserProfile>
  ) {
    return this.usersService.activate(action.payload.id).pipe(
      tap((response) => Observable.create(response)),
      catchError((response) => throwError(response.error))
    );
  }
}
