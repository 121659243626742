import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FeatureApiClientBase,
  ApiSearchRequestBody,
} from '@ms/angular-workspace/dist/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IUserProfile } from '../store/models';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends FeatureApiClientBase<IUserProfile> {
  constructor(private httpClient: HttpClient) {
    super(httpClient, 'user');
  }

  resendInvite(id: number): Observable<any> {
    return this.httpClient
      .post(this.url(`${id}/resend-invite`), {})
      .pipe(map((response) => response));
  }

  activate(id: number): Observable<any> {
    return this.httpClient
      .post(this.url(`${id}/activate`), {})
      .pipe(map((response) => response));
  }

  exportCSV(data: ApiSearchRequestBody | void) {
    return this.httpClient
      .post(this.url('export'), data, { responseType: 'blob' })
      .pipe(map((response) => response));
  }
}
