import { State, StateContext, Selector } from '@ngxs/store';
import { Injectable, Injector } from '@angular/core';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';
import { Observable, throwError, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  ApiSearchRequestBody,
  ApiSearchResponseData,
  downloadBlob,
} from '@ms/angular-workspace/dist/core';

import { ITenant } from './models';
import { TenantsService } from '../services/tenants.service';

@Injectable()
export class TenantsStateModel {
  tenants: ApiSearchResponseData<ITenant> | null;
}

@State<TenantsStateModel>({
  name: 'tenants',
  defaults: {
    tenants: null,
  },
})
@Injectable()
export class TenantsState {
  private static tenantsService: TenantsService;

  constructor(injector: Injector) {
    TenantsState.tenantsService = injector.get<TenantsService>(TenantsService);
  }

  @Selector()
  static tenants(state: TenantsStateModel) {
    return state.tenants;
  }

  @Receiver({ type: '[Tenants] Search Tenants' })
  public static search(
    ctx: StateContext<TenantsStateModel>,
    action: EmitterAction<ApiSearchRequestBody>
  ) {
    return this.tenantsService
      .search({
        ...action.payload,
      })
      .pipe(
        tap((response) => {
          ctx.patchState({
            tenants: response.data,
          });
          return of(response.data);
        })
      );
  }

  @Receiver({ type: '[Tenants] Export Tenants' })
  public static exportCSV(
    ctx: StateContext<TenantsStateModel>,
    action: EmitterAction<ApiSearchRequestBody | void>
  ) {
    return this.tenantsService.exportCSV(action.payload).pipe(
      tap((response) => {
        downloadBlob(response, 'Organizations Export.csv');
        return of(response);
      }),
      catchError((response) => throwError(response.error))
    );
  }

  @Receiver({ type: '[Tenants] Create Tenant' })
  public static create(
    ctx: StateContext<TenantsStateModel>,
    action: EmitterAction<ITenant>
  ) {
    return this.tenantsService.create(action.payload).pipe(
      tap((response) => Observable.create(response)),
      catchError((response) => throwError(response.error))
    );
  }

  @Receiver({ type: '[Tenants] Patch Tenant' })
  public static patch(
    ctx: StateContext<TenantsStateModel>,
    action: EmitterAction<{
      currentItem: ITenant;
      propertiesToUpdate: string[];
    }>
  ) {
    return this.tenantsService
      .patch(action.payload.currentItem, action.payload.propertiesToUpdate)
      .pipe(tap((response) => Observable.create(response)));
  }

  @Receiver({ type: '[Tenants] Deactivate Subscription' })
  public static deactivateSubscription(
    ctx: StateContext<TenantsStateModel>,
    action: EmitterAction<string>
  ) {
    return this.tenantsService.deactivateSubscription(action.payload);
  }

  @Receiver({ type: '[Tenants] Reactivate Subscription' })
  public static reactivateSubscription(
    ctx: StateContext<TenantsStateModel>,
    action: EmitterAction<string>
  ) {
    return this.tenantsService.reactivateSubscription(action.payload);
  }
}
