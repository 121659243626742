import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Emittable, EmitterService } from '@ngxs-labs/emitter';
import { SubscriptionsManager } from '@ms/angular-workspace/dist/core';
import { Toast } from 'primeng/toast';
import { ToastItemCloseEvent } from 'primeng/toast/toast.interface';
import { AuthActions } from './store/models';
import { AuthState } from './store/state';
import { Observable, filter } from 'rxjs';

declare global {
  interface Window {
    Appcues: any;
  }
}

@Component({
  selector: 'auth-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends SubscriptionsManager implements OnInit {
  title = 'Auth';
  user: any;

  private loadProfile: Emittable<void> = inject(EmitterService).action(
    AuthState.loadProfile
  );
  private _userProfile$: Observable<any> = inject(Store).select(
    AuthState.profile
  );

  @ViewChild(Toast) toast: Toast;

  constructor(private store: Store, private router: Router) {
    super();
  }

  public ngOnInit(): void {
    this.subs = this._userProfile$
      .pipe(filter((userProfile) => !!userProfile))
      .subscribe((userProfile) => {
        this.user = userProfile;
        this.initAppcuesUser();
      });

    this.store
      .dispatch(AuthActions.CheckAuth)
      .subscribe(() => this.store.dispatch(this.loadProfile.emit()));
  }

  parseRoute(event: Event) {
    const route = (event.target as HTMLInputElement).getAttribute('href');

    if (route) {
      event.preventDefault(); // prevent standard href navigation, which leads to a page refresh
      this.router.navigateByUrl(route as string);
      this.toast.onMessageClose(event as unknown as ToastItemCloseEvent); // close the toast
    }
  }

  initAppcuesUser() {
    if (!this.user?.id) return;

    window.Appcues.identify(this.user.appCuesUserId, {
      first_name: this.user.name.firstName,
      last_name: this.user.name.lastName,
      email: this.user.email,
      organization: this.user.tenant?.name,
      organization_id: this.user.tenant?.id,
      organization_status: this.user.tenant?.isSubscriptionActive
        ? 'active'
        : 'inactive',
      organization_type: this.user.tenant?.clientType?.name,
      subscription_tier: this.user.tenant?.subscription?.name,
      state_access: this.user.tenant.states.join(', '),
      status: this.user.status,
      created_date: this.user.createdDate,
      invited_date: this.user.invitedDate,
      last_login_date: this.user.lastLoginDate,
    });
  }
}
