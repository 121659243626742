// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

export const environment = {
  production: false,
  hideElttoUi: false,
  oidc: {
    apiPrefix: 'api/v1',
    issuer: 'https://localhost:5002/',
    allowedUrls: [
      '/api/v1',
      'https://localhost:5002/api/v1',
      'https://localhost:9002/api/v1',
      'https://localhost:7206/api/v1',
    ],
    app: 'mi',
  },
  ngxs: {
    plugins: [
      NgxsReduxDevtoolsPluginModule.forRoot({ name: 'MarketInsights' }),
    ],
  },
  productUrls: {
    elttoUrl: 'https://localhost:4201',
    miUrl: 'https://localhost:4202',
    authUrl: 'https://localhost:4200',
  },
  gaId: 'G-N8S2M90J40',
  googleMap: {
    mapId: '2e5981ea6d07830b',
    defaultDistance: 10, // 10 miles
    minDistance: 1,
    maxDistance: 200,
    maxMarkers: 1000,
    staticStreetViewApiKey: 'AIzaSyD1FfBmEDMNUNYAOz0WhsblJxDhE56cuBY',
  },
  tableauService: {
    serverUrl: 'https://tableau-dev.mountainseedapp.com',
    timeoutRetriesAttempts: 1,
    resultsTimeoutDurationInSeconds: 5,
    mapTimeoutDurationInSeconds: 14,
    timeoutFiltersExpirationInMinutes: 60,
  },
  propertySearchChipBar: {
    chipRemovalReapplyFiltersDebounceTime: 1000,
  },
  serviceUrls: {
    propertySearch: 'https://localhost:9002',
    workspace: 'https://localhost:7206',
  },
  workspace: {
    savedSearchesTooltipLabel: `Saved searches allow you to save the criteria for searches you frequently run so that you don't have to re-enter the same criteria again later. A search may be saved from anywhere in the application where search criteria may be applied.</br>
    To run a saved search, select "Run Search" from the action menu.`,
    compsTooltipLabel: `Easily refer back to properties you frequently search and compare them all in one space. Once a property has been added to your workspace, you can view its details, export it into a PDF, or export data for multiple properties into a spreadsheet.`,
  },
  surveyMonkeyLink: 'https://www.surveymonkey.com/r/N5P6SFT',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
