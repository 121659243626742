import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import {
  MsComponentsModule,
  MsLogoModule,
  MsModalModule,
} from '@ms/angular-workspace/dist/components';
import { MsCoreModule } from '@ms/angular-workspace/dist/core';
import { NgxsEmitPluginModule } from '@ngxs-labs/emitter';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { environment } from '../../../mi/src/environments/environment';
import { ServerErrorInterceptor } from './interceptors/server-error.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ngxsOptions, ngxsStates } from './ngxs.config';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    //
    AppRoutingModule,
    //
    MsCoreModule,
    MsComponentsModule,
    MsLogoModule,
    MsModalModule,
    //
    NgxsModule.forRoot(ngxsStates, ngxsOptions),
    NgxsRouterPluginModule.forRoot(),
    NgxsEmitPluginModule.forRoot(),
    ...environment.ngxs.plugins,
    NgxGoogleAnalyticsModule.forRoot(environment.gaId),
    NgxGoogleAnalyticsRouterModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    MessageService,
    DialogService,
    ConfirmationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
  ],
})
export class AppModule {}
