import { NgxsModuleOptions } from '@ngxs/store';
import { StateClass } from '@ngxs/store/internals';

import { environment } from '../environments/environment';
import { AuthState } from './store/state';
import { LookupsState } from './store/lookups.state';
import { TenantsState } from './store/tenants.state';
import { UsersState } from './store/users.state';

export const ngxsStates: StateClass[] = [
  AuthState,
  LookupsState,
  TenantsState,
  UsersState,
];

export const ngxsOptions: NgxsModuleOptions = {
  developmentMode: !environment.production,
};
