import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  Emittable,
  EmitterAction,
  EmitterService,
  Receiver,
} from '@ngxs-labs/emitter';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { environment } from '../../environments/environment';
import {
  AuthActions,
  AuthStateModel,
  IChangePasswordPayload,
  IChangePasswordShort,
  IUserProfile,
  UserRole,
} from './models';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    done: false,
    isAuthenticated: false,
    products: [],
    profile: null,
  },
})
@Injectable()
export class AuthState {
  private static http: HttpClient;
  private static $gaService: GoogleAnalyticsService;

  constructor(private http: HttpClient, injector: Injector) {
    AuthState.http = injector.get<HttpClient>(HttpClient);
    AuthState.$gaService = injector.get<GoogleAnalyticsService>(
      GoogleAnalyticsService
    );
    AuthState._loadProfile = injector
      .get<EmitterService>(EmitterService)
      .action(AuthState.loadProfile);
  }

  @Selector()
  static products(state: AuthStateModel): any[] {
    return state.products;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return state.isAuthenticated;
  }

  @Selector()
  static isDone(state: AuthStateModel): boolean {
    return state.done;
  }

  @Selector()
  static profile(state: AuthStateModel): IUserProfile | null {
    if (!state.profile) {
      return null;
    }

    return {
      ...state.profile,
      isElttoSubscribed: !!state.profile.elttoRoleId,
      isMISubscribed: !!state.profile.miRoleId,
      isAdmin:
        state.profile.miRoleId === UserRole.MI_ADMIN ||
        state.profile.elttoRoleId === UserRole.ELTTO_ADMIN,
      isMountainSeedUser: state.profile.tenant?.isInternal || false,
    } as IUserProfile;
  }

  private static _loadProfile: Emittable<void>;

  @Action(AuthActions.SetApplications) setApplications(
    ctx: StateContext<AuthStateModel>,
    action: AuthActions.SetApplications
  ) {
    ctx.patchState({
      products: action.applications,
      isAuthenticated: true,
      done: true,
    });
  }

  @Action(AuthActions.CheckAuth) checkAuth(ctx: StateContext<AuthStateModel>) {
    return this.http.get('/api/v1/account/userinfo').pipe(
      tap(
        (result) => {
          const { isElttoSubscribed, isMISubscribed } = result as any;
          const mi = isMISubscribed
            ? [
                {
                  label: 'MountainSeed Analytics',
                  url: environment.miUrl,
                  image: 'https://via.placeholder.com/288x200',
                },
              ]
            : [];
          const eltto = isElttoSubscribed
            ? [
                {
                  label: 'MountainSeed Eltto',
                  url: environment.elttoUrl,
                  image: 'https://via.placeholder.com/288x200',
                },
              ]
            : [];
          ctx.patchState({ products: [...eltto, ...mi] });
          ctx.dispatch(new AuthActions.SetApplications([...eltto, ...mi]));
        },
        (error) => {
          ctx.patchState({ done: true, isAuthenticated: false });
        },
        () => {
          const { isAuthenticated } = ctx.getState();
          if (!isAuthenticated) {
            ctx.patchState({ done: true, isAuthenticated: false });
          }
        }
      )
    );
  }

  @Receiver({ type: '[AuthState] Load Profile' })
  public static loadProfile(
    ctx: StateContext<AuthStateModel>,
    action: EmitterAction<void>
  ) {
    return this.http.get('/api/v1/account/userprofile').pipe(
      tap((response) => {
        const userProfile = response as IUserProfile;
        ctx.patchState({ profile: response as IUserProfile });
        this.$gaService.gtag('event', 'login', {
          user_id: userProfile.id,
          organization_name: userProfile.tenant?.name,
          invited_date:
            userProfile.invitedDate != null
              ? new Date(userProfile.invitedDate).toLocaleDateString('en-US')
              : null,
        });
        return Observable.create(response);
      }),
      catchError((response) => throwError(response.error))
    );
  }

  @Receiver({ type: '[AuthState] Save Profile' })
  public static saveProfile(
    ctx: StateContext<AuthStateModel>,
    action: EmitterAction<IUserProfile>
  ) {
    return this.http.post('/api/v1/account/userprofile', action.payload).pipe(
      switchMap(() => this._loadProfile.emit()),
      catchError((response) => throwError(response.error))
    );
  }

  @Receiver({ type: '[AuthState] Change Password' })
  public static changePassword(
    ctx: StateContext<AuthStateModel>,
    action: EmitterAction<IChangePasswordPayload>
  ) {
    return this.http
      .post('/api/v1/account/change-password', action.payload)
      .pipe(
        switchMap(() => this._loadProfile.emit()),
        catchError((response) => throwError(response.error))
      );
  }

  @Receiver({ type: '[AuthState] Update Password' })
  public static updatePassword(
    ctx: StateContext<AuthStateModel>,
    action: EmitterAction<IChangePasswordShort>
  ) {
    return this.http
      .post('/api/v1/account/update-password', action.payload)
      .pipe(
        switchMap(() => this._loadProfile.emit()),
        catchError((response) => throwError(response.error))
      );
  }
}
