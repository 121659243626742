export const environment = {
  production: true,
  hideElttoUi: true,
  apiUrl: 'https://auth.mountainseedapp.com/api',
  elttoUrl: 'https://eltto.mountainseedapp.com/',
  miUrl: 'https://analytics.mountainseedapp.com/',
  ngxs: {
    plugins: [],
  },
};
