import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { State, StateContext, Selector } from '@ngxs/store';
import { throwError } from 'rxjs';

import {
  IState,
  IUserRole,
  IUserInfoStatus,
  ISubscriptionTier,
  IInquiryType,
  IClientType,
} from './models';
import { EmitterAction, Receiver } from '@ngxs-labs/emitter';

@Injectable()
export class LookupsStateModel {
  states: IState[];
  miRoles: IUserRole[];
  elttoRoles: IUserRole[];
  applicationUserInfoStatuses: IUserInfoStatus[];
  subscriptionTiers: ISubscriptionTier[];
  inquiryTypes: IInquiryType[];
  clientTypes: IClientType[];
}

@State<LookupsStateModel>({
  name: 'lookups',
  defaults: {
    states: [],
    miRoles: [],
    elttoRoles: [],
    applicationUserInfoStatuses: [],
    subscriptionTiers: [],
    inquiryTypes: [],
    clientTypes: [],
  },
})
@Injectable()
export class LookupsState {
  private static http: HttpClient;

  constructor(private http: HttpClient, injector: Injector) {
    LookupsState.http = injector.get<HttpClient>(HttpClient);
  }

  @Selector()
  static states(state: LookupsStateModel) {
    return state.states;
  }

  @Selector()
  static miRoles(state: LookupsStateModel) {
    return state.miRoles;
  }

  @Selector()
  static elttoRoles(state: LookupsStateModel) {
    return state.elttoRoles;
  }

  @Selector()
  static applicationUserInfoStatuses(state: LookupsStateModel) {
    return state.applicationUserInfoStatuses;
  }

  @Selector()
  static subscriptionTiers(state: LookupsStateModel) {
    return state.subscriptionTiers;
  }

  @Selector()
  static inquiryTypes(state: LookupsStateModel) {
    return state.inquiryTypes;
  }

  @Selector()
  static clientTypes(state: LookupsStateModel) {
    return state.clientTypes;
  }

  @Receiver({ type: '[Lookups] Lookups Load' })
  public static load(
    ctx: StateContext<LookupsStateModel>,
    action: EmitterAction<void>
  ) {
    return this.http.get('/api/v1/lookups').subscribe({
      next: (response: any) => {
        ctx.patchState(response.data);
      },
      error: (error) => {
        throwError(() => new Error(error));
      },
    });
  }
}
